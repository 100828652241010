// General
* {
  font-family: var(--font-family);
}
body {
  font-family: var(--font-family);
  font-size: var(--medium-font-size);
}
h1 {
  font-size: var(--heading-medium-font-size);
  line-height: var(--heading-medium-line-height);
  margin: 0;
  margin-bottom: var(--default-padding);
}
h2 {
  font-size: var(--heading-medium-font-size);
  line-height: var(--heading-medium-line-height);
}
.pi {
  font-size: var(--small-font-size);
}
.p-password {
  input {
    width: 100%;
  }
}
.p-disabled,
.p-component:disabled {
  opacity: 0.5;
}
.p-component {
  font-size: var(--xsmall-font-size);
  line-height: var(--xsmall-line-height);
}
.p-tag {
  padding: 4px var(--xsmall-padding);
  border-radius: 4px;
  .p-tag-value,
  .p-tag-icon {
    font-size: var(--small-font-size);
    line-height: var(--small-line-height);
    font-weight: 600;
  }
}
label {
  font-size: var(--xsmall-font-size);
  line-height: var(--xsmall-line-height);
  font-weight: 500;
}
.p-inputtextarea {
  border-radius: var(--small-border-radius) !important;
  min-height: var(--default-textarea-height) !important;
}
.red {
  color: var(--red-main) !important;
}
.p-button.input-eye-button {
  margin-top: 29px !important;
  margin-left: var(--small-padding) !important;
  font-size: var(--medium-font-size) !important;
  line-height: var(--medium-line-height) !important;
}
.secondary-text {
  color: var(--gray-darker);
  font-size: var(--xsmall-font-size);
  line-height: var(--xsmall-line-height);
}

.field-checkbox label {
  margin-left: var(--small-padding);
}

.upcoming-course {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
}

.hide-first-letter {
  .p-multiselect-label {
    flex-wrap: nowrap;
  }
  .p-multiselect-token-label {
    &::first-letter {
      color: var(--gray-darker);
      margin-right: 4px;
    }
  }

  // Add more if needed
  .p-multiselect-item span {
    &::first-letter {
      font-size: 0;
      line-height: 0;
      visibility: hidden;
    }
  }
}
// Scrollbars
::-webkit-scrollbar {
  width: var(--medium-padding);
}
::-webkit-scrollbar-track {
  width: var(--small-padding);
  border-radius: var(--small-padding);
  background-color: var(--white-main);
}
::-webkit-scrollbar-thumb {
  border-radius: var(--small-padding);
  border: 4px solid var(--white-main);
  background: var(--gray-dark);
}
::-webkit-scrollbar-thumb:hover {
  background-color: var(--gray-darker);
}
::-webkit-scrollbar-corner {
  background-color: var(--white-main);
}
// Multiselect
.p-multiselect {
  align-items: center;
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  border-radius: var(--default-border-radius);
  border-color: var(--beige-main);
  background-color: var(--beige-main);
  min-height: var(--default-input-height);
  gap: var(--small-padding);

  &.p-disabled {
    opacity: 0.5;
  }

  &.white {
    background-color: var(--white-main);
    .p-multiselect-label .p-multiselect-token {
      background-color: var(--beige-main);
    }
  }

  .p-multiselect-label {
    font-family: var(--font-family);
    padding: 10px var(--default-padding) !important;
    white-space: pre-wrap;
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
  }

  .p-multiselect-trigger {
    width: auto;
    padding-right: var(--default-padding);
  }

  .p-multiselect-label.p-placeholder,
  .p-multiselect-trigger-icon {
    font-size: var(--small-font-size);
    line-height: var(--small-line-height);
    color: var(--gray-darker);
  }
  .p-multiselect-token {
    margin: 0;
    .p-multiselect-token-icon {
      margin-left: var(--small-padding) !important;
      background: none;
      border: none;
      padding: 0;
      cursor: pointer;
      &:hover {
        color: var(--red-main);
      }
    }
  }

  &:not(.p-disabled),
  &.p-disabled {
    .p-multiselect-token {
      border-radius: var(--medium-border-radius);
      background-color: var(--white-main);
      color: var(--black-main);
      margin: 0;
      height: 28px;
      font-size: var(--xsmall-font-size);
      line-height: var(--xsmall-line-height);
      &:hover {
        background-color: var(--red-pale);
      }
    }
    &:hover {
      border-color: var(--red-main);
    }

    &.p-focus {
      border-color: var(--red-main);
      box-shadow: var(--box-shadow-red-no-blur);
    }
  }
}
.p-multiselect-panel {
  box-shadow: var(--box-shadow-black);
  border-radius: var(--xsmall-border-radius);
  padding: 0;
  overflow: hidden;
  margin: 4px 0;
  min-width: 100px;

  &.bulk {
    .p-multiselect-header {
      align-items: flex-start;

      .p-checkbox {
        margin-top: var(--small-padding);
      }

      button {
        margin-top: var(--xsmall-padding);
      }
    }
  }

  .p-multiselect-header {
    background-color: var(--white-main);
    border-color: var(--gray-dark);
    gap: var(--small-padding);
    padding: var(--medium-padding);

    .field {
      margin-bottom: 0;
    }

    .p-multiselect-close {
      color: var(--black-main);
      background: transparent;
      &:enabled:hover {
        background: transparent;
        color: var(--red-main);
      }
      &:enabled:focus {
        box-shadow: var(--box-shadow-red);
      }
    }
  }
  .p-multiselect-items {
    padding: 0;

    .p-multiselect-item {
      font-size: var(--small-font-size);
      line-height: var(--small-line-height);
      color: var(--black-main);
      padding: var(--medium-padding);
      &.p-highlight {
        color: var(--red-main);
        background: var(--gray-pale);
        box-shadow: none;
      }
      &:not(.p-highlight):not(.p-disabled):hover {
        background: var(--gray-pale);
        color: var(--black-main);
      }
      &:focus {
        box-shadow: none;
      }
    }
  }
  .p-multiselect-footer {
    border-top: 1px solid var(--gray-dark);
    padding: var(--medium-padding);
  }
}
// Autocomplete
.p-autocomplete {
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  border-radius: var(--default-border-radius);
  border-color: var(--beige-main);
  background-color: var(--beige-main);

  .p-autocomplete-dd-input.p-autocomplete-input,
  .p-autocomplete-dd-input.p-autocomplete-multiple-container {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  &.p-inputwrapper-focus {
    .p-button {
      box-shadow: var(--box-shadow-red-no-blur) !important;
    }
  }

  .p-autocomplete-token {
    height: 28px;
    margin: 0 !important;
  }

  .p-autocomplete-multiple-container {
    min-height: var(--default-input-height);
    height: auto;
    padding: 10px var(--default-padding);
    font-size: var(--small-font-size);
    line-height: var(--small-line-height);
    color: var(--black-main);
    white-space: pre-wrap;
    display: flex;
    flex-wrap: wrap;
    gap: 6px;

    .p-autocomplete-input-token {
      padding: 0;

      input {
        font-size: var(--small-font-size);
        line-height: var(--small-line-height);
        color: var(--black-main);
        font-weight: 400;
        font-family: var(--font-family);
      }
    }

    &:not(.p-disabled) {
      .p-autocomplete-token {
        border-radius: var(--medium-border-radius);
        background-color: var(--white-main);
        color: var(--black-main);
        .p-autocomplete-token-icon:before {
          content: '\e90b';
        }
        .p-autocomplete-token-icon:hover {
          color: var(--red-main);
        }
        &:hover {
          background-color: var(--red-pale);
        }
      }
      &:hover {
        border-color: var(--red-main);
      }

      &.p-focus {
        border-color: var(--red-main);
        box-shadow: var(--box-shadow-red-no-blur);
      }
    }
  }

  .p-button.p-autocomplete-dropdown {
    background: var(--red-main);
    color: var(--white-main);
    border: 1px solid var(--red-main);
    border-radius: 0 var(--default-border-radius) var(--default-border-radius) 0;
    padding: 10px var(--small-padding);

    &:enabled:hover,
    &:enabled:hover {
      background: var(--red-main);
      color: var(--white-main);
      border: 1px solid var(--red-main);
    }
  }

  &.p-autocomplete-multiple {
    display: flex;

    ul {
      width: 100%;
    }
  }
}
.p-autocomplete-panel {
  box-shadow: var(--box-shadow-black);
  border-radius: var(--xsmall-border-radius);
  margin: 4px 0;

  .p-autocomplete-items {
    font-size: var(--small-font-size);
    line-height: var(--small-line-height);
    padding: 0;
    min-width: auto !important;

    .p-autocomplete-item {
      padding: var(--medium-padding) var(--small-padding);
      border-bottom: 1px solid var(--gray-pale);
    }

    .p-autocomplete-item:hover,
    .p-autocomplete-item.p-highlight {
      color: var(--black-main);
      background: var(--gray-pale);
    }
  }
}
// Dropdown
.p-dropdown {
  font-family: var(--font-family);
  font-size: var(--xsmall-font-size);
  line-height: var(--xsmall-line-height);
  align-items: center;
  min-height: var(--default-input-height);
  border-radius: var(--default-border-radius);
  border-color: var(--beige-main);
  background-color: var(--beige-main);
  gap: var(--small-padding);

  &.white,
  &.white .p-dropdown-label {
    background-color: var(--white-main);
  }

  &.p-dropdown-clearable {
    .p-dropdown-clear-icon {
      right: 48px;
      margin-top: -7px;
    }
  }

  .p-dropdown-label {
    display: flex;
    align-items: center;
    font-family: var(--font-family);
    color: var(--black-main);
    padding: 10px var(--default-padding);

    &.p-placeholder {
      font-size: var(--small-font-size);
      line-height: var(--small-line-height);
      color: var(--gray-darker);
    }
  }

  .p-dropdown-trigger {
    width: auto;
    padding-right: var(--default-padding);
  }

  .p-dropdown-clear-icon,
  .p-dropdown-trigger-icon {
    color: var(--gray-darker);
    font-size: var(--small-font-size);
  }

  .p-dropdown-clear-icon:hover {
    color: var(--red-main);
  }

  &:not(.p-disabled).p-focus,
  &:not(.p-disabled):hover {
    border-color: var(--red-main) !important;
  }

  &:not(.p-disabled).p-focus {
    box-shadow: var(--box-shadow-red-no-blur);
  }
}
.p-dropdown-panel {
  box-shadow: var(--box-shadow-black);
  border-radius: var(--xsmall-border-radius);
  overflow: hidden;
  margin: 4px 0;

  .p-dropdown-header {
    background: var(--white-main);
    padding: var(--medium-padding) var(--small-padding);
    .p-dropdown-filter {
      margin: 0;
      height: var(--default-input-height);
      padding: 10px calc(var(--default-padding) * 2) 10px var(--default-padding);
    }
    .p-dropdown-filter-icon {
      margin-top: -7px;
      right: 24px;
    }
  }

  &.without-border {
    .p-dropdown-items .p-dropdown-item {
      border-bottom: 1px solid transparent !important;
    }
  }

  .p-dropdown-items {
    padding: 0;

    .p-dropdown-item-group {
      color: var(--gray-darker);
      font-weight: 500;
      padding: var(--small-padding);
    }

    .p-dropdown-item {
      font-size: var(--small-font-size);
      line-height: var(--small-line-height);
      padding: var(--medium-padding) var(--small-padding);
      color: var(--black-main);
      border-bottom: 1px solid var(--gray-pale);
    }
    .p-dropdown-item:has(.upcoming) {
      background-color: var(--beige-main) !important;
    }
  }

  .p-dropdown-items .p-dropdown-item.p-highlight {
    color: var(--red-main);
    background: var(--gray-pale);
  }

  .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    color: var(--black-main);
    background: var(--gray-pale);
  }

  .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
    background: transparent;

    &:hover {
      color: var(--black-main);
      background: var(--gray-pale);
    }
  }
}
// Chips
.p-chips {
  align-items: center;
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  border-radius: var(--default-border-radius);
  border-color: var(--beige-main);
  background-color: var(--beige-main);
  min-height: var(--default-input-height);
  gap: var(--small-padding);

  &.p-disabled {
    opacity: 0.5;
  }

  &:not(.p-disabled).p-focus .p-chips-multiple-container {
    border-color: transparent;
    box-shadow: var(--box-shadow-red-no-blur);
  }

  .p-chips-multiple-container {
    font-family: var(--font-family);
    padding: 10px var(--default-padding) !important;
    white-space: pre-wrap;
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    height: auto;
    width: 100%;

    &.p-focus {
      border-color: var(--red-main);
      box-shadow: var(--box-shadow-red-no-blur);
    }
  }

  input::placeholder {
    font-size: var(--small-font-size);
    line-height: var(--small-line-height);
    color: var(--gray-darker);
  }

  .p-chips-token-label {
    margin: 0;

    .p-multiselect-token-icon {
      margin-left: var(--small-padding) !important;
      background: none;
      border: none;
      padding: 0;
      cursor: pointer;
      &:hover {
        color: var(--red-main);
      }
    }
  }

  &:not(.p-disabled),
  &.p-disabled {
    .p-chips-token {
      border-radius: var(--medium-border-radius);
      background-color: var(--white-main);
      color: var(--black-main);
      margin: 0;
      height: 28px;
      font-size: var(--xsmall-font-size);
      line-height: var(--xsmall-line-height);
      &:hover {
        background-color: var(--red-pale);
      }
    }
    &:hover {
      border-color: var(--red-main);
    }

    &.p-focus {
      border-color: var(--red-main);
      box-shadow: var(--box-shadow-red-no-blur);
    }
  }
}
// Buttons
.p-button:focus {
  box-shadow: var(--box-shadow-red);
}
.p-button.p-button-secondary:enabled:focus {
  box-shadow: var(--box-shadow-red);
}
// Tags
.p-tag.orange {
  background: #ff6c00;
}
.p-tag.gray {
  background: #8e8e8e;
}
// Switches
.p-inputswitch {
  width: 42px;
  min-width: 42px;
  height: 24px;
  min-height: 24px;
  .p-inputswitch-slider:before {
    width: 18px;
    height: 18px;
    margin: 2px;
    top: 0;
    left: 0;
  }
  &.p-inputswitch-checked .p-inputswitch-slider:before {
    transform: translateX(18px);
  }

  &.p-focus .p-inputswitch-slider {
    box-shadow: var(--box-shadow-red);
  }
}
// Tab menus
.p-tabmenu {
  margin-bottom: var(--default-padding);
  .p-tabmenu-nav {
    border-color: transparent;
    gap: var(--small-padding) var(--default-padding);
    flex-wrap: wrap;

    .p-tabmenuitem {
      .p-menuitem-link {
        overflow: visible;
        padding: var(--small-padding) 0;
        font-weight: 500;
        font-size: var(--xsmall-font-size);
        line-height: var(--xsmall-line-height);
        text-transform: uppercase;
        border-color: transparent;
        color: var(--black-main);
        &:not(.p-disabled):focus,
        &:not(.p-disabled):focus-within {
          color: var(--red-main);
          box-shadow: none;
        }
      }
      &:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
        color: var(--red-main);
        border-color: transparent;
      }
    }
  }
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  color: var(--black-main);
  border-color: var(--red-main);
}
// Input group
.p-inputgroup {
  &:has(.group-button) {
    .p-dropdown,
    .p-inputtext,
    .p-multiselect {
      border-radius: 0px !important;
    }

    .p-dropdown-trigger,
    .p-multiselect-trigger {
      padding-right: var(--xxsmall-padding);
    }

    .p-inputgroup-addon {
      border-right: 1px solid var(--gray-dark);
      padding: 10px var(--small-padding) 10px var(--default-padding);
    }
  }

  .p-dropdown,
  .p-inputtext,
  .p-multiselect {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .p-dropdown-label,
  .p-inputtext,
  .p-multiselect-label {
    padding: 10px var(--xsmall-padding) !important;
  }

  .p-inputgroup-addon {
    min-width: var(--default-padding);
    border-top-left-radius: var(--default-border-radius);
    border-bottom-left-radius: var(--default-border-radius);
    font-family: var(--font-family);
    font-size: var(--small-font-size);
    font-weight: 600;
    color: var(--gray-darker);
    line-height: var(--small-line-height);
    border-color: var(--beige-main);
    background-color: var(--beige-main);
    padding: 10px var(--xsmall-padding) 10px var(--medium-padding);
  }
}

// Input fields
.p-input-icon-left {
  > i {
    margin-top: -7px;
  }
  .p-inputtext {
    padding-left: calc(var(--default-padding) * 2);
  }
  > i:first-of-type {
    color: var(--gray-darker);
    left: 24px;
  }
}
.p-input-icon-right {
  > i {
    margin-top: -7px;
  }
  .p-inputtext {
    padding-right: calc(var(--default-padding) * 2);
  }
  > i:first-of-type {
    color: var(--gray-darker);
    right: 24px;
  }
}
.p-inputtext {
  &.white {
    background-color: var(--white-main);
  }
}
.p-inputnumber {
  &.white .p-inputnumber-input {
    background-color: var(--white-main);
  }
}
.p-inputnumber-button-group {
  .p-button {
    background-color: transparent;
    color: var(--gray-darker);
    border: none;
    border-radius: var(--default-border-radius);

    &:hover {
      color: var(--red-dark);
    }
  }
}
.p-inputtext {
  font-family: var(--font-family);
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  color: var(--black-main);
  border-color: var(--beige-main);
  background-color: var(--beige-main);
  border-radius: var(--default-border-radius);
  padding: 10px var(--default-padding);
  gap: var(--small-padding);
  height: var(--default-input-height);

  &::placeholder {
    color: var(--gray-darker) !important;
  }

  &:enabled:hover,
  &:enabled:focus {
    border-color: var(--red-main);
  }

  &:enabled:focus {
    box-shadow: var(--box-shadow-red-no-blur);
  }

  &.p-disabled {
    opacity: 0.5;
  }
}
// Links
a:focus-visible {
  box-shadow: var(--box-shadow-red);
  outline: 0;
}
// Forms
form {
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
}
// Steps
.p-steps {
  width: 100%;
  background-color: var(--gray-pale);
  padding: var(--default-padding);
  border-radius: 5px;

  .p-steps-item.p-highlight .p-steps-number {
    font-size: var(--medium-font-size);
    color: var(--red-main);
    background: var(--gray-pale);
  }

  .p-steps-item:before {
    border-top: 1px solid var(--gray-dark) !important;
  }

  .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
    box-shadow: none !important;
  }

  .p-menuitem-link {
    background-color: var(--gray-pale) !important;
  }

  .p-steps-number {
    background-color: var(--white-main) !important;
    border: 1px solid var(--gray-dark) !important;
  }

  .p-steps-number,
  .p-steps-title {
    font-weight: 700;
    color: var(--black-main) !important;
    font-size: var(--xsmall-font-size) !important;
  }
  .p-steps-current {
    .p-steps-number,
    .p-steps-title {
      color: var(--red-main) !important;
    }
  }
}
// Menus
.p-menu {
  &.p-menu-overlay {
    box-shadow: var(--box-shadow-black);
    border-radius: var(--xsmall-border-radius);
    padding: 0;
    overflow-y: scroll;
    max-height: 100dvh;
    margin: 4px 0;

    &::-webkit-scrollbar {
      width: 0 !important;
    }

    .p-menuitem {
      border-bottom: 1px solid var(--gray-pale);
    }

    .p-menuitem-link {
      padding: var(--medium-padding) var(--small-padding);
      box-shadow: none;
      font-size: var(--small-font-size);
      line-height: var(--small-line-height);

      .p-menuitem-text,
      .p-menuitem-icon {
        color: var(--black-main);
      }

      &:hover {
        .p-menuitem-text,
        .p-menuitem-icon {
          color: var(--black-main);
        }
      }
    }
  }
  .p-menuitem-link:not(.p-disabled):hover {
    background: var(--gray-pale);
  }
  .p-submenu-header {
    display: none;
  }
  .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
    background: var(--gray-pale);
  }
}
// Checkboxes
.p-checkbox {
  &:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: var(--red-main);
  }

  &:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    box-shadow: var(--box-shadow-red);
    border-color: var(--red-main);
  }

  &:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: var(--red-main);
    background: var(--red-main);
  }

  .p-checkbox-box {
    align-items: center !important;
    border: 1px solid var(--gray-dark);
    border-radius: 2px;

    &.p-highlight {
      border-color: var(--red-main);
      background: var(--red-main);
    }
  }
}
// Radio
.p-radiobutton {
  width: var(--default-padding);
  height: var(--default-padding);
  border-radius: 2px;
  background: transparent;
  padding: 3px;
  margin-right: 4px;

  .p-radiobutton-box {
    width: 18px;
    height: 18px;
    border-radius: 2px;
    border: 1px solid var(--gray-pale);

    &:not(.p-disabled):not(.p-highlight):hover {
      border-color: var(--red-main);
    }

    &.p-highlight {
      border-color: var(--red-main);
      background: var(--red-main);
      box-shadow: none;

      &:not(.p-disabled):hover {
        border-color: var(--red-main);
        background: var(--red-main);
        color: var(--white-main);
      }
    }

    .p-radiobutton-icon {
      width: 18px;
      height: 18px;
      background: transparent;

      &::before {
        font-family: var(--icon-font-family);
        content: '\f00c';
        display: inline-block;
        color: var(--white-main);
        vertical-align: middle;
        font-weight: 900;
        font-size: var(--small-padding);
        padding-left: 2px;
      }
    }
  }
}
.p-circle {
  .p-radiobutton-box {
    border-color: var(--gray-darker);
    border-radius: 50%;

    .p-radiobutton-icon {
      width: 10px;
      height: 10px;
      background: transparent;
      background: var(--white-main);
      border-radius: 50%;

      &::before {
        display: none;
      }
    }
  }
}
// Inline messages
.p-inline-message {
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  border-radius: var(--small-border-radius);
  padding: var(--medium-padding) var(--default-padding);
  border: 0;

  .p-inline-message-icon {
    font-size: var(--heading-medium-font-size);
    line-height: var(--medium-line-height);
    margin-right: var(--medium-padding);
  }

  .p-inline-message-text {
    color: var(--black-main);
    font-size: var(--small-font-size);
    line-height: var(--small-line-height);
    font-weight: 500;
    width: 100%;
  }

  &.p-inline-message-warn {
    background: var(--orange-pale);

    .p-inline-message-icon {
      color: var(--orange-main);
    }
  }

  &.p-inline-message-success {
    background: var(--green-pale);

    .p-inline-message-icon {
      color: var(--green-main);
    }
  }

  &.p-inline-message-error {
    background: var(--red-pale);

    .p-inline-message-icon {
      color: var(--red-main);
    }
  }

  &.p-inline-message-info {
    background: var(--gray-pale);

    .p-inline-message-icon {
      color: var(--black-main);
    }
  }
}

.show-selected-text {
  font-size: var(--small-font-size);
  color: var(--red-main);
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
// Paginator
.p-paginator {
  border: none;
  padding: var(--medium-padding) 0;
  align-self: flex-end;
  color: var(--black-main);
  .p-paginator-first,
  .p-paginator-prev,
  .p-paginator-next,
  .p-paginator-last {
    color: var(--black-main);
  }
  .p-paginator-pages .p-paginator-page {
    color: var(--black-main);
    &:not(.p-highlight):hover {
      background: var(--beige-main);
      border-color: var(--beige-main);
    }
    &.p-highlight {
      background: var(--beige-main);
      border-color: var(--beige-main);
      color: var(--red-main);
    }
  }

  .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
  .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
  .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
  .p-paginator-last:not(.p-disabled):not(.p-highlight):hover,
  .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
    background: var(--gray-pale);
    border-color: var(--gray-pale);
    color: var(--black-main);
  }

  .p-link:focus {
    box-shadow: var(--box-shadow-red);
  }
}
// Input switches
.p-inputswitch {
  .p-focus .p-inputswitch-slider {
    box-shadow: var(--box-shadow-red) !important;
  }

  &.p-inputswitch-checked[aria-checked='true'] {
    .p-inputswitch-slider,
    &:not(.p-disabled):hover .p-inputswitch-slider {
      background-color: var(--green-main) !important;
    }
  }

  &[aria-checked='false'] {
    .p-inputswitch-slider,
    &:not(.p-disabled):hover .p-inputswitch-slider {
      background-color: var(--purple-dark);
    }
  }
}
// Dialog
.p-dialog {
  max-height: 100%;
  border-radius: var(--medium-border-radius);
  overflow: hidden;
  .p-dialog-title {
    max-width: 95%;

    h1 {
      color: var(--black-main);
      font-size: var(--heading-small-font-size);
      line-height: var(--heading-small-line-height);
      margin: 0;
    }
  }

  .p-dialog-header {
    font-size: var(--body-font-size);
    line-height: var(--body-line-height);
    padding: var(--default-padding) var(--default-padding) var(--xsmall-padding);
    max-width: 100%;

    .p-dialog-header-icons {
      margin-left: auto;
    }

    .p-dialog-header-icon:enabled:hover {
      background: transparent;
      color: var(--red-main);
    }
    .p-dialog-header-icon:focus {
      box-shadow: var(--box-shadow-red);
    }
  }

  .p-dialog-content {
    font-size: var(--medium-font-size);
    line-height: var(--medium-line-height);
    padding: var(--medium-padding) var(--default-padding);
  }

  .p-dialog-footer {
    padding: var(--default-padding);
  }

  &.v2 {
    .p-dialog-title {
      max-width: 85%;
      font-size: var(--modal-heading-font-size);
      line-height: var(--modal-heading-line-height);

      h1 {
        font-size: var(--modal-heading-font-size);
        line-height: var(--modal-heading-line-height);
      }
    }

    .p-dialog-header {
      padding: calc(var(--semi-big-padding) - 5px) var(--big-padding)
        var(--xsmall-padding);
    }

    .p-dialog-content {
      font-size: var(--small-font-size);
      line-height: var(--small-line-height);
      padding: var(--semi-big-padding) var(--big-padding);
      padding-top: 0;
    }

    .actions {
      margin-top: var(--default-padding);
    }
  }
}
// Datepicker
.p-datepicker {
  width: auto;
  font-family: var(--font-family) !important;
  border-radius: var(--medium-border-radius);
  box-shadow: var(--box-shadow-black);
  color: var(--black-main) !important;

  table td.p-datepicker-today > span {
    background: var(--beige-main);
    color: var(--black-main);
  }

  .p-datepicker-header {
    .p-datepicker-prev:enabled:hover,
    .p-datepicker-next:enabled:hover {
      background: var(--beige-main);
    }
    .p-datepicker-prev:focus,
    .p-datepicker-next:focus {
      box-shadow: var(--box-shadow-red);
    }

    .p-datepicker-title .p-link {
      font-family: var(--font-family) !important;
    }
    .p-datepicker-title .p-link:enabled:hover {
      color: var(--red-main);
    }
    .p-datepicker-title .p-link:focus {
      box-shadow: var(--box-shadow-red);
    }
  }

  .p-monthpicker .p-monthpicker-month.p-highlight,
  .p-yearpicker .p-yearpicker-year.p-highlight {
    background: var(--red-main);
    color: var(--white-main);
  }

  .p-datepicker-calendar-container {
    th {
      font-weight: 400;
      color: var(--gray-darker);
    }

    .p-datepicker-today.p-highlight,
    span.p-highlight {
      background: var(--red-main) !important;
      color: var(--white-main) !important;
    }
    span:focus {
      box-shadow: var(--box-shadow-red);
    }
  }

  &:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus,
  &:not(.p-disabled)
    .p-monthpicker
    .p-monthpicker-month:not(.p-disabled):focus {
    box-shadow: var(--box-shadow-red);
  }

  &:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
    background: var(--beige-main);
  }

  .p-timepicker {
    button:enabled:hover {
      background: var(--beige-main);
    }
    button:focus {
      box-shadow: var(--box-shadow-red);
    }
  }

  .p-datepicker-buttonbar {
    padding: var(--xsmall-padding) 0 0 0;
    .p-button {
      border-radius: var(--medium-border-radius);
      &:not(.p-disabled):hover {
        background: var(--beige-main);
      }
    }
    .p-button-label {
      color: var(--gray-darker);
      transition: none;
    }
  }
}
//Calendar
.p-calendar:not(.p-calendar-disabled).p-focus > .p-inputtext {
  box-shadow: 0 0 0 2px var(--red-main);
}
// Tree structure
.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
  box-shadow: var(--box-shadow-red);
}
// Tooltip
.p-tooltip {
  max-width: 300px !important;
}
.p-tooltip-arrow {
  display: none;
}
.p-tooltip-text {
  background-color: var(--purple-dark) !important;
  color: var(--white-main) !important;
}
// File upload
.p-fileupload.p-fileupload-basic {
  .p-button {
    height: var(--default-input-height);
    color: var(--purple-dark);
    background: var(--white-main);
    border-color: var(--purple-dark);
    font-size: var(--small-font-size);
    line-height: var(--small-line-height);
    border-radius: var(--default-border-radius);

    &:not(button):not(a):not(.p-disabled):hover {
      background: var(--red-dark);
      border-color: var(--red-dark);
      color: var(--white-main);
    }

    .p-button-label {
      font-weight: 500;
      transition: none;
    }
  }
}
// Bee editor
#beeplugin-editor {
  display: grid;
  min-height: 800px;
}
// Campaign and template structure
#campaign-form-container {
  flex: 1 1 auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  padding: 0;
}
#eFront-wizard-migration-footer-container,
#campaign-footer-container,
#template-footer-container {
  width: calc(100% + (var(--default-padding) * 2));
  flex: 0 1 auto;
  align-self: flex-start;
  padding: var(--default-padding);
  margin: 0 calc(-1 * var(--default-padding)) calc(-1 * var(--default-padding));
  border-top: 1px solid var(--gray-dark);
  background: var(--white-main);
  z-index: 1;
}
#eFront-wizard-migration-content-container,
#camapign-content-container {
  flex: 1 1 auto;
  width: 100%;
}
#template-content-container {
  flex: 1 1 auto;
  overflow-y: auto;
  width: calc(100% + (var(--default-padding) * 2));
  margin: 0 calc(-1 * var(--default-padding));
  > div {
    height: 100%;
  }
}
#campaign-header-container,
#template-header-container {
  width: 100%;
  flex: 0 1 auto;
}
#selected-template-tag {
  color: var(--red-main);
  font-size: var(--medium-font-size);
  line-height: var(--medium-line-height);
  vertical-align: middle;
}
// Multiline datatable
.multiline-datatable {
  &.p-datatable {
    .p-datatable-table {
      height: 100%;
      width: 100%;
      min-width: 700px;
    }
    .main-info {
      span {
        color: var(--gray-darker);
        font-size: var(--xsmall-font-size);
        line-height: var(--xsmall-line-height);
      }
    }
    &.with-actions {
      td[role='cell']:last-child,
      tr th[role='columnheader']:last-child {
        width: 80px !important;
      }
      td[role='cell']:first-child,
      tr th[role='columnheader']:first-child {
        width: auto;
      }
    }
    td[role='cell']:last-child,
    tr th[role='columnheader']:last-child {
      width: auto !important;
      text-align: left !important;
      .p-column-header-content {
        justify-content: flex-start !important;
      }
    }

    td[role='cell']:first-child,
    tr th[role='columnheader']:first-child {
      width: 20%;
    }

    tr td[role='cell'] {
      &.vertical-top:not(:has(.p-skeleton)) {
        vertical-align: top;
      }
      padding: var(--medium-padding) !important;
      &:last-child.no-padding:not(:has(.p-skeleton)),
      &.no-padding:not(:has(.p-skeleton)) {
        padding: 0 !important;
      }
      .group-cell {
        height: auto !important;
        div.group-row {
          display: flex;
          flex: 1;
          width: 100%;
          min-height: 65px;
          max-height: 65px;
          overflow: hidden;
          padding: var(--medium-padding) !important;
          border-bottom: 1px solid var(--gray-dark);
          align-items: center;
          &:last-child {
            border-bottom: 0;
          }
        }
      }
    }
  }
}
// Simple Datatable
.simple-datatable {
  &.p-datatable {
    border: none !important;
    border-radius: 0 !important;

    .p-datatable-thead > tr > th {
      color: var(--black-main);
    }

    .p-datatable-wrapper {
      border-radius: 0 !important;
      th[role='columnheader'] {
        background-color: transparent !important;
        border-bottom: 1px solid var(--gray-dark) !important;
        padding: 0 var(--medium-padding) var(--medium-padding) 0 !important;

        &.find-user:last-child {
          width: 30px !important;
        }
      }

      td[role='cell'] {
        border-bottom: 1px solid var(--gray-dark) !important;
        padding-block: 0 !important;
        padding-left: 0 !important;

        &:last-child {
          padding: 0 !important;
          height: 60px !important;
          &.find-user {
            width: 30px !important;
          }
        }
      }

      tr {
        border-bottom: 1px solid var(--gray-dark) !important;

        &:nth-child(even),
        &:nth-child(odd) {
          background-color: transparent !important;
        }

        &:not(.p-datatable-row-expansion):hover {
          background-color: transparent !important;
        }

        &:last-child {
          td[role='cell'] {
            border-bottom: none !important;
          }
        }
      }
    }

    .p-datatable-header {
      padding: 0 !important;
      padding-bottom: var(--medium-padding) !important;
    }
  }
  &.security-culture {
    &.p-datatable {
      .p-datatable-wrapper th[role='columnheader'] {
        border-bottom: none !important;
        &:last-child {
          padding-right: 0px !important;
          width: max-content !important;
        }
      }
      .p-column-title {
        width: 100% !important;
        &:not(:first-child) {
          text-align: center !important;
        }
      }
    }
  }
}
// Rich Text Editor
.quill {
  overflow: hidden;
  &::-webkit-scrollbar,
  ::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
  height: 100%;
  background-color: var(--beige-main);
  border: 1px solid var(--beige-main);
  border-radius: var(--small-border-radius);
  min-height: 130px;
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  font-family: var(--font-family);
  &:hover {
    border: 1px solid var(--red-main);
  }
  &.p-editor-error,
  &.p-editor-focused {
    border: 1px solid var(--red-main);
  }
  &.p-editor-focused {
    box-shadow: var(--box-shadow-red-no-blur);
  }
  &.p-editor-disabled {
    pointer-events: none !important;
    opacity: 0.5;
  }
  .ql-container,
  .ql-toolbar {
    border: none;
    text-align: right;
  }
  .ql-toolbar {
    border-bottom: 1px solid var(--gray-dark);
  }
  .ql-editing.ql-tooltip {
    z-index: 1;
    left: 0 !important;
    border-radius: var(--small-border-radius);
    border-color: var(--gray-dark);
    padding: var(--xsmall-padding) var(--small-padding);
    box-shadow: none;
    > a {
      color: var(--red-main);
      font-weight: 500;
      &:hover {
        color: var(--red-dark);
      }
    }
    > input {
      border-radius: var(--xsmall-border-radius);
      border-color: var(--beige-main);
      background-color: var(--beige-main);
      overflow: hidden;
      &:enabled:focus-visible,
      &:enabled:hover {
        border-color: var(--red-main) !important;
      }
      &:enabled:focus-visible {
        box-shadow: var(--box-shadow-red-no-blur) !important;
        outline: none;
      }
    }
  }
  .ql-toolbar {
    .ql-formats {
      margin: 0;
      border-right: 1px solid var(--gray-dark);
      margin-right: var(--xxsmall-padding);
      padding-right: var(--xxsmall-padding);

      &:last-child {
        border: none;
        padding: 0;
        margin: 0;
      }
    }
    button,
    .ql-picker-label {
      .ql-stroke,
      .ql-fill {
        stroke: var(--gray-darker);
        color: var(--gray-darker);
      }
      &:hover,
      &:focus {
        .ql-stroke,
        .ql-fill {
          stroke: var(--red-dark) !important;
          color: var(--red-dark) !important;
        }
      }
      &.ql-active {
        .ql-stroke,
        .ql-fill {
          stroke: var(--red-main) !important;
          color: var(--red-main) !important;
        }
      }
    }
    .ql-script,
    .ql-strike {
      .ql-fill {
        fill: var(--gray-darker);
      }

      &.ql-active {
        .ql-stroke,
        .ql-fill {
          fill: var(--red-main) !important;
        }
      }

      &:hover,
      &:focus {
        .ql-stroke,
        .ql-fill {
          fill: var(--red-dark) !important;
        }
      }
    }
    .ql-picker.ql-size {
      width: 80px;
    }
    .ql-size.ql-picker.ql-expanded {
      .ql-picker-label {
        stroke: var(--red-dark) !important;
        color: var(--red-dark) !important;
      }
    }
    .ql-picker-label,
    .ql-picker-item {
      border: 0;
      stroke: var(--gray-darker);
      color: var(--gray-darker);
      text-align: left;

      &:hover,
      &.ql-active {
        stroke: var(--red-dark) !important;
        color: var(--red-dark) !important;
      }
    }
  }
  .ql-container {
    padding: 10px var(--default-padding);
    word-break: break-word;
    .ql-editor {
      padding: 0;
    }
    .ql-blank:before {
      left: var(--default-padding);
      right: auto;
      color: var(--gray-darker);
      font-size: var(--small-font-size);
      line-height: var(--small-line-height);
      font-style: normal;
    }
  }
}

.ql-editor ol,
.ql-editor ul {
  padding: 0 !important;
}
.ql-editor {
  width: 100%;
}
// Overlay Panel
.p-overlaypanel {
  &:before {
    content: none;
  }

  &:after {
    content: none;
  }

  .p-overlaypanel-close {
    background: transparent;
    color: var(--black-main);
    top: 12px;
    right: 12px;
  }
}

// Panel menu
.p-panelmenu {
  .p-panelmenu-header .p-panelmenu-header-content {
    background-color: transparent;
    border: 0;
  }

  .p-panelmenu-content {
    .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
      background-color: transparent;
    }

    .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
      > .p-menuitem-content {
      background: transparent;
    }
  }
}
// Scroll panel
.p-scrollpanel {
  .p-scrollpanel-wrapper {
    padding-right: var(--medium-padding);
  }

  .p-scrollpanel-content {
    padding-right: 0;
    padding-bottom: 0;
  }

  .p-scrollpanel-bar {
    background: var(--gray-dark);
    border-radius: var(--small-border-radius);
  }
}
// Skeleton
.p-skeleton {
  background-color: var(--gray-pale);
}
